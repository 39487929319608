type LogFunction = (...args: any[]) => void;

export type Loggers = {
  debug:   LogFunction,
  log:     LogFunction,
  info:    LogFunction,
  warn:    LogFunction,
  error:   LogFunction,
};

const LOGGING_ENABLED = true;

function createLogFunction(method: keyof Console, prefix: string, style: string): LogFunction {
  const noop: LogFunction = () => {};

  /* eslint-disable no-console */
  return (...args: any[]) => {
    if (LOGGING_ENABLED) {
      // Dynamically access the method and call it
      const logMethod = console[method] as LogFunction;
      logMethod.apply(console, [`%c[${prefix}]`, style, ...args]);
    } else {
      noop();
    }
  };
  /* eslint-enable */
}

function createLoggers(
  prefix: string,
  prefixColor: string,
): Loggers {
  const style = `background: ${prefixColor}; color: rgba(0, 0, 0, 0.6)`;

  /* eslint-disable no-console */
  return {
    debug: createLogFunction('debug', prefix, style),
    log: createLogFunction('log', prefix, style),
    info: createLogFunction('info', prefix, style),
    warn: createLogFunction('warn', prefix, style),
    error: createLogFunction('error', prefix, style),
  };
  /* eslint-enable */
}

export const LOGGERS = {
  migration: createLoggers('Session migration', '#96e693'),
  provisioning: createLoggers('Provisioning', '#d3ffd1'),
  transfer: createLoggers('Transfer', '#b3edff'),
  video: createLoggers('Video', '#ffd1d1'),
  ccp: createLoggers('CCP', '#ffd1f7'),
  network: createLoggers('Network', '#fff3d1'),
  webRtc: createLoggers('WebRTC', '#f5de9d'),
  application: createLoggers('Application', '#a6cfe0'),
  audio: createLoggers('Audio', '#ffce8f'),
  browser: createLoggers('Browser', '#c7cdff'),
  fileTransfer: createLoggers('FileTransfer', '#d5a2f2'),
  keyboardManager: createLoggers('Keyboardmanager', '#e2f2a2'),
  diagnostics: createLoggers('Diagnostics', '#fa69b4'),
  development: createLoggers('Development', '#bada55'),
  vtc: createLoggers('VTC', '#ffce8f')
};
